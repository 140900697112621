<template>
    <div class="status-indicator">
        {{ statusText }} <span
            class="bubble"
            :class="statusClass"
        ></span>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: "Not Connected"
        },
        status: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            statusClass: "unset"
        };
    },
    computed: {
        statusText() {
            if (this.status === true) {
                this.statusClass = "connected";
                return this.$i18n.t("connected");
            } else {
                this.statusClass = "nope";
                return this.$i18n.t("disconnected");
            }
        }
    },
};
</script>

<style lang="scss" scoped>
    .status-indicator {
        text-align: center;
        font-size: 0.75rem;
        color: #999;
        margin-bottom: 0.5rem;
    }
    .bubble {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #DDD;
        &.connected {
            background: #afce00;
        }
    }
</style>
