<template>
    <div class="form-group">
        <span class="input-wrapper">
            <label
                for="url-input"
                class="input-prefix"
            >https://</label>
            <input
                id="url-input"
                ref="urlInput"
                autocomplete="off"
                type="text"
                :value="value"
                :placeholder="placeholder"
                @input="validateInput"
            />
        </span>
    </div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
    name: "LinkInput",
    props: {
        placeholder: { type: [String, Number], default: "..." },
        value: { type: [String, Number], default: "" },
    },
    methods: {
        focus() {
            this.$refs.urlInput.focus();
        },
        validateInput: debounce(function($event) {
            const value = $event.target.value;
            const urlMatcher = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}(:\d{1,5}|\.[a-zA-Z]{2,})?\b([-a-zA-Z0-9@:%_+.~#?&//=()]*)/g);
            if (urlMatcher.test(value) && value.startsWith("https://")) {
                this.$emit("input", value.slice(8));
            } else {
                this.$emit("input", value);
            }
        }, 500),
    }
};
</script>

<style lang="scss" scoped>
.input-wrapper {
    display: flex;
    align-items: stretch;

    .input-prefix {
        border-radius: 3px 0 0 3px;
        background: var(--low-contrast, #{$light-grey});
        padding: 0.75rem;
        user-select: none;
        opacity: 1;
        margin: 0;
    }

    input {
        border-radius: 0 3px 3px 0;
    }
}
</style>

<docs>
```vue
<link-input/>
```
</docs>
