<template>
    <div class="integration">
        <StatusIndicator
            v-if="!fetchingInitialState && !isIntranetConnecting"
            :status="connectionState !== null && connectionState.enabled"
        />
        <LoadingWrapper :is-loading="fetchingInitialState || isIntranetConnecting">
            <div v-if="connectionState && connectionState.enabled">
                <div class="content">
                    <div class="text">
                        {{ $t("connect_with_atlassian_is_enabled") }} {{ connectionState.config.instanceUrl }}
                    </div>
                </div>
                <div class="actions">
                    <app-button
                        id="remove-connection-flow"
                        @click="removeConnection"
                    >
                        {{ $t("connect_with_atlassian_connection_remove") }}
                    </app-button>
                </div>
            </div>
            <div v-else>
                <div class="content">
                    <div class="text">
                        {{ $t("connect_with_atlassian_is_disabled") }}
                    </div>
                    <div class="form-group">
                        <LinkInput
                            v-model="instanceUrl"
                            placeholder="mycompany.atlassian.net"
                        />
                    </div>
                    <div class="form-group">
                        <input
                            id="confluence"
                            v-model="withConfluence"
                            type="checkbox"
                        />
                        <label for="confluence">{{ $t("confluence") }}</label>
                    </div>
                    <div class="form-group">
                        <input
                            id="jira"
                            v-model="withJira"
                            type="checkbox"
                        />
                        <label for="jira">{{ $t("jira") }}</label>
                    </div>
                </div>
                <div class="actions">
                    <app-button
                        id="start-connection-flow"
                        :class="!isValidConfiguration ? 'disabled' : '' "
                        @click="startConnectionFlow"
                    >
                        {{ $t("connect_with_atlassian_connection_start") }}
                    </app-button>
                </div>
            </div>
        </LoadingWrapper>
    </div>
</template>

<script>
import Vue from "vue";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    mapActions,
    mapState
} from "vuex";
import { analytics } from "@web/analytics";
import { atlassianConnectService } from "@web/services";
import { getGlobalConfiguration } from "@web/global-config";
import { RELOAD_CURRENT_INTRANET } from "@web/store/intranet/actions";
import LinkInput from "@web/components/LinkInput";
import StatusIndicator from "@web/views/intranet/UserSettings/integration/StatusIndicator";

export default Vue.extend({
    name: "Atlassian3LOConnect",
    components: {
        StatusIndicator,
        LinkInput,
    },
    data() {
        return {
            fetchingInitialState: true,
            connectionState: null,
            instanceUrl: "",
            isIntranetConnecting: false,
            withJira: false,
            withConfluence: false,
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
        isValidConfiguration() {
            return isValidAtlassianInstanceUrl("https://" + this.instanceUrl) && (this.withConfluence || this.withJira);
        },
    },
    methods: {
        ...mapActions({
            reloadIntranet: INTRANET_MODULE_NAME + RELOAD_CURRENT_INTRANET
        }),
        async fetchStatus() {
            this.connectionState = await atlassianConnectService.getIntranetConnectionStatus(this.intranet.uid);
            this.fetchingInitialState = false;
        },
        async startConnectionFlow() {
            this.isIntranetConnecting = true;
            const popup = await atlassianConnectService.startConnectionFlow(this.intranet.uid, "https://" + this.instanceUrl, this.withConfluence, this.withJira);
            const channel = new BroadcastChannel("connect-atlassian-intranet");
            channel.onmessage = ({ data }) => {
                const msg = data.data;
                this.fetchStatus();
                this.isIntranetConnecting = false;
                analytics.log(getGlobalConfiguration().analytics_event_name_atl_cloud_connected_by_admin);
                popup.close();

                this.$notify({
                    group: "app",
                    type: msg.includes("success") ? "success" : "error",
                    text: this.$t(msg),
                    duration: 8000,
                });
                channel.close();
                this.reloadIntranet();
            };
        },
        async removeConnection() {
            this.fetchingInitialState = true;
            await atlassianConnectService.removeConnection(this.intranet.uid);
            this.fetchStatus();
            this.reloadIntranet();
        },
    },
    mounted() {
        this.fetchStatus();
    }
});

const isValidAtlassianInstanceUrl = string => {
    try {
        const url = new URL(string);
        return url.protocol.startsWith("https") && url.hostname.endsWith(".atlassian.net");
    } catch (_) {
        return false;
    }
};
</script>

<style lang="scss" scoped>
</style>
