<template>
    <section class="connections">
        <h2>{{ $t("app_integrations") }}</h2>
        <p class="intro">
            Activate app integration possibilities to enable dashboard widgets,
            profile integrations and quick access functions for your users.
        </p>
        <div class="integrations-grid">
            <VendorTile
                :title="$i18n.t('atlassian_cloud')"
                icon="atlassian-icon"
            >
                <Atlassian3LOConnect />
            </VendorTile>
            <VendorTile
                title="Google"
                icon="google-icon"
            >
                <GoogleCalendarConnect />
            </VendorTile>
        </div>
    </section>
</template>

<script>

import Atlassian3LOConnect from "@web/views/intranet/UserSettings/integration/Atlassian3LOConnect";
import GoogleCalendarConnect from "@web/views/intranet/UserSettings/integration/GoogleCalendarConnect";
import VendorTile from "@web/views/intranet/UserSettings/integration/VendorTile";

export default {
    name: "IntegrationSettings",
    components: {
        Atlassian3LOConnect,
        GoogleCalendarConnect,
        VendorTile
    }
};
</script>

<style lang="scss" scoped>
    .integrations-grid {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    }
</style>
