<template>
    <div class="integration">
        <StatusIndicator
            v-if="connectionState"
            :status="connectionState.enabled"
        />
        <LoadingWrapper :is-loading="fetchingInitialState || isIntranetConnecting">
            <template v-if="connectionState && connectionState.enabled">
                <div class="content">
                    <div class="text">
                        {{ $t("connect_with_google_calendar_is_enabled") }} {{ connectionState.config.instanceUrl }}
                    </div>
                </div>
                <div class="actions">
                    <app-button
                        id="remove-connection-flow"
                        @click="removeConnection"
                    >
                        {{ $t("connect_with_google_calendar_connection_remove") }}
                    </app-button>
                </div>
            </template>
            <template v-else>
                <div class="content">
                    <div class="text">
                        {{ $t("connect_with_google_calendar_is_disabled") }}
                    </div>
                </div>
                <div class="actions">
                    <app-button
                        id="start-connection-flow"
                        @click="connect"
                    >
                        {{ $t("connect_with_google_calendar_connection_start") }}
                    </app-button>
                </div>
            </template>
        </LoadingWrapper>
    </div>
</template>

<script>
import Vue from "vue";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    mapActions,
    mapState
} from "vuex";
import { googleCalendarConnectService } from "@web/services";
import { RELOAD_CURRENT_INTRANET } from "@web/store/intranet/actions";
import StatusIndicator from "@web/views/intranet/UserSettings/integration/StatusIndicator";

export default Vue.extend({
    name: "GoogleCalendarConnect",
    components: {
        StatusIndicator,
    },
    data() {
        return {
            fetchingInitialState: true,
            connectionState: null,
            isIntranetConnecting: false,
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
    },
    methods: {
        ...mapActions({
            reloadIntranet: INTRANET_MODULE_NAME + RELOAD_CURRENT_INTRANET
        }),
        async fetchStatus() {
            this.connectionState = await googleCalendarConnectService.getIntranetConnectionStatus(this.intranet.uid);
            this.fetchingInitialState = false;
        },
        async connect() {
            this.isIntranetConnecting = true;
            await googleCalendarConnectService.connect(this.intranet.uid)
                .then(() => {
                    this.$notify({
                        group: "app",
                        type: "success",
                        text: this.$t("connect_with_google_calendar_connection_success"),
                        duration: 8000,
                    });
                }).catch((r) => {
                    this.isIntranetConnecting = false;
                    this.$notify({
                        group: "app",
                        type: "error",
                        text: this.$t(r.toString()),
                        duration: 8000,
                    });
                    this.reloadIntranet();
                }).finally(() => {
                    this.fetchStatus();
                    this.isIntranetConnecting = false;
                    this.reloadIntranet();
                });
        },
        async removeConnection() {
            this.fetchingInitialState = true;
            await googleCalendarConnectService.removeConnection(this.intranet.uid);
            this.fetchStatus();
            this.reloadIntranet();
        }
    },
    mounted() {
        this.fetchStatus();
    }
});
</script>

<style lang="scss" scoped>

</style>
