<template>
    <article class="card padding-medium">
        <div class="card-content">
            <div class="header">
                <div
                    v-if="icon"
                    class="vendor-icon"
                    v-html="require(`@web/assets/vendors/${icon}.svg`)"
                ></div>
                <h4>
                    {{ title }}
                </h4>
            </div>
            <slot ></slot>
        </div>
    </article>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "Integration"
        },
        icon: {
            type: String
        }
    }
};
</script>

<style lang="scss" scoped>
    .card {
        display: flex;
        flex-direction: column;
    }

    .header {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin-bottom: 1.5rem;
        .vendor-icon {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.5rem;
        }
        h4 {
            margin: 0;
            padding: 0;
        }
    }

    .card-content {
        flex: 1 1 auto;
        position: relative;
    display: flex;
    flex-direction: column;

        &::v-deep {
            .status-indicator {
                position: absolute;
                right: 0;
                top: 0rem;
            }

            .integration {
                flex: 1;
                .content {
                    flex: 1 0 auto;
                }
            }

            .text {
                font-size: 0.75rem;
                line-height: 1.4;
                color: #666;
                margin-bottom: 1.5rem;
            }
        }
    }
</style>
